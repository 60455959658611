import Axios from 'axios'
import { Crud } from '.'

export default class ArticlesService extends Crud {
  constructor () {
    super('new/articles')
  }

  // getAll =>    params = { per_page , filter , page , category }    || => return list article with pagination

  // getById =>   return article with tags and personas               ||

  // add =>        params = { per_page , filter , page , category }    ||
  //              data => {article:{} , tags[] , personas : []}       ||

  // update =>     params = { per_page , filter , page , category }    ||
  //              data => {article:{} , tags[] , personas : []}       ||

  // delete =>     params = { per_page , filter , page , category }    ||

  async getByCategoryAndTitle(params){
    return await Axios.get(this.endPoint + '/get-by-category-and-title/one' ,{params}).then(response => Promise.resolve(response)).catch(error => Promise.reject(error))
  }

  async getPublicArticle(id , params = {}){
    return await Axios.get(this.endPoint + '/public/'+id ,{params}).then(response => response).catch(error => error)
  }

  async addViewNbr(id , params = {}){
    return await Axios.get(this.endPoint + '/addViewNbr/' + id ,{params}).then(response => response).catch(error => error)
  }

  async listFourArt(id , params = {}){
    return await Axios.get(this.endPoint + '/listFourArt/' + id ,{params}).then(response => response).catch(error => error)
  }

  async getMangementArticle( params = {}){
    return await Axios.get(this.endPoint + '/managment/all'  ,{params}).then(response => response).catch(error => error)
  }

  async addVideoView(id , params = {}){
    return await Axios.get(this.endPoint + '/video-views/' +id  ,{params}).then(response => response).catch(error => error)
  }

  async setNewOrder(data , params = {}){
    return await Axios.post(this.endPoint + '/order/set'  ,data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error))
  }
}
