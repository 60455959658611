import axios from 'axios';
import { ActivityService, AssignedsService, CategoryService, ClientsService, CommentsService, ExportsService, PersonaService, RemarksService, TagsService, UsersService } from '@/services/ApiService';
import InscriptionsService from '@/services/ApiService/inscriptions.services';

const back = {
    state: {
        status: 'admin connected',
        token: localStorage.getItem('token') || '',
        listComment: '',
        AllNbrUsers: '',
        AllCategoryGraphe: '',
        HotContent: '',
        AllPersonaGraphe: '',
        ActionPerM: '',
        TagsCloudALL: '',
        LAssigned: '',
        LAssignedAdmin: '',
        UnassignedLeadsAdm: '',
        UnassignedLeadsKey: '',
        NewLeadsAdm: '',
        ActivityKeyAccount: '',
        UserList: '',
        Activity: '',
        UserDetail: '',
        UserTagCloud: '',
        UserLastActivity: '',
        UserDonut: '',
        UserActivity: '',
        UserRemarks: '',
        UserLastExp: '',
        KeyAccount: '',
        TestClient: '',
        UserListKeyaccount: '',
        keyAccountLeads: '',
        NewLeadsKeyACC: ''
    },
    mutations: {
        GetNmbersUsers(state, AllNbrUsers) {
            state.AllNbrUsers = AllNbrUsers;
            state.status = 'Get All Nbr Users Sucess';
        },
        GetPersonaGraphe(state, AllPersonaGraphe) {
            state.AllPersonaGraphe = AllPersonaGraphe;
            state.status = 'Get All Cat persona Sucess';
        },
        GetCategoryGraphe(state, AllCategoryGraphe) {
            state.AllCategoryGraphe = AllCategoryGraphe;
            state.status = 'Get All Cat Graphe Sucess';
        },
        GetHotContent(state, HotContent) {
            state.HotContent = HotContent;
            state.status = 'Get 4 top viewed posts Sucess';
        },
        GetActionPerMonth(state, ActionPerM) {
            state.ActionPerM = ActionPerM;
            state.status = 'Get actions per month Sucess';
        },
        GetListComment(state, listComment) {
            state.listComment = listComment;
            state.status = 'Get listComment Sucess';
        },
        GetTagCloud(state, TagsCloudALL) {
            state.TagsCloudALL = TagsCloudALL;
            state.status = 'Get Tags Cloud Sucess';
        },
        GetListAssigned(state, LAssigned) {
            state.LAssigned = LAssigned;
            state.status = 'Get List Assigned Sucess';
        },
        GetListAssignedAdmin(state, LAssignedAdmin) {
            state.LAssignedAdmin = LAssignedAdmin;
            state.status = 'Get List Assigned admin Sucess';
        },
        GetUnassignedLeadsAdmin(state, UnassignedLeadsAdm) {
            state.UnassignedLeadsAdm = UnassignedLeadsAdm;
            state.status = 'Get List UnAssigned leads admin Sucess';
        },
        GetUnassignedLeadsKeyAccount(state, UnassignedLeadsKey) {
            state.UnassignedLeadsKey = UnassignedLeadsKey;
            state.status = 'Get List UnAssigned leads key account Sucess';
        },
        GetNewLeadsAdmin(state, NewLeadsAdm) {
            state.NewLeadsAdm = NewLeadsAdm;
            state.status = 'Get List New admin Sucess';
        },
        GetNewLeadsKeyAccount(state, NewLeadsKeyACC) {
            state.NewLeadsKeyACC = NewLeadsKeyACC;
            state.status = 'Get List key account Sucess';
        },
        GetActivityKeyAccount(state, ActivityKeyAccount) {
            state.ActivityKeyAccount = ActivityKeyAccount;
            state.status = 'Get List Activity Sucess';
        },
        GetActivity(state, Activity) {
            state.Activity = Activity;
            state.status = 'Get List Activity Sucess';
        },
        GetUserList(state, UserList) {
            state.UserList = UserList;
            state.status = 'Get List users Sucess';
        },
        GetUserListKeyAccount(state, UserListKeyaccount) {
            state.UserListKeyaccount = UserListKeyaccount;
            state.status = 'Get List users Sucess';
        },
        GetkeyAccountLeads(state, keyAccountLeads) {
            state.keyAccountLeads = keyAccountLeads;
            state.status = 'Get List leads Sucess';
        },
        DetailUser(state, UserDetail) {
            state.UserDetail = UserDetail;
            state.status = 'Get one user Sucess';
        },
        ActionUser(state, UserAction) {
            state.UserAction = UserAction;
            state.status = 'Get one user actions Sucess';
        },
        TagCloudUser(state, UserTagCloud) {
            state.UserTagCloud = UserTagCloud;
            state.status = 'Get user tag cloud Sucess';
        },
        LastActivityUser(state, UserLastActivity) {
            state.UserLastActivity = UserLastActivity;
            state.status = 'Get user tag cloud Sucess';
        },
        DonutsUser(state, UserDonut) {
            state.UserDonut = UserDonut;
            state.status = 'Get user donuts Sucess';
        },
        ActivityUser(state, UserActivity) {
            state.UserActivity = UserActivity;
            state.status = 'Get user activity Sucess';
        },
        RemarksUser(state, UserRemarks) {
            state.UserRemarks = UserRemarks;
            state.status = 'Get user commentss Sucess';
        },
        LastExportUser(state, UserLastExp) {
            state.UserLastExp = UserLastExp;
            state.status = 'Get user last export Sucess';
        },
        ListKeyAcoount(state, KeyAccount) {
            state.KeyAccount = KeyAccount;
            state.status = 'Get key account list Sucess';
        },
        isClient(state, TestClient) {
            state.TestClient = TestClient;
            state.status = 'Get client or not Sucess';
        }
    },
    actions: {
        async NumberUserInterest({ commit }, params = {}) {
            let service = new InscriptionsService();
            service.getUserInterests(params).then(response => {
                        commit('GetNmbersUsers', response.data);
                    })
                    .catch(error => {});
        },
        async CategoryGraphe({ commit }) {
            let service = new CategoryService();
            service.postChart().then(response => commit('GetCategoryGraphe', response.data))
                .catch(error => {});
        },
        async PersonaGraphe({ commit }) {
            let service = new PersonaService();
            service.postChart().then(response => commit('GetPersonaGraphe', response.data))
                .catch(error => {});
        },
        async HotContents({ commit }, month) {
            let service = new PersonaService();
            service.hotContent(month).then(response => commit('GetHotContent', response.data))
                .catch(error => {});
        },
        async ActionPerMonth({ commit }, month) {
            let service = new UsersService();
            service.actionPerMonth(month).then(response => commit('GetActionPerMonth', response.data))
                .catch(error => {});
        },
        async listComment({ commit }) {
            let service = new CommentsService();
            service.getAll().then(response =>  commit('GetListComment', response.data))
                .catch(error => {});
        },
        async TagCloud({ commit }, UserType) {
            let service = new TagsService();
            service.tagClouds(UserType).then(response => commit('GetTagCloud', response.data))
                .catch(error => {});
        },
        async ListAssignedKeyAccount({ commit }) {
            let service = new AssignedsService();
            service.keyAccountList().then(response => commit('GetListAssigned', response.data))
                .catch(error => {});

        },
        async ListAssignedAdmin({ commit }) {
            let service = new AssignedsService();
            service.adminList().then(response => commit('GetListAssignedAdmin', response.data))
                .catch(error => {});
        },
        async UnassignedLeadsAdmin({ commit }) {
            let service = new AssignedsService();
            service.adminUnassignedLeads().then(response => commit('GetUnassignedLeadsAdmin', response.data))
                .catch(error => {});
        },
        async UnassignedLeadsKeyAccount({ commit }) {
            const headers = {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            };
            await axios
                .get(process.env.VUE_APP_API_ENDPOINT + 'UnassignedLeadsKeyAccount', { headers })
                .then(response => commit('GetUnassignedLeadsKeyAccount', response.data))
                .catch(error => {});
        },
        async NewLeadsAdmin({ commit }) {
            let service = new AssignedsService();
            service.adminLeads().then(response => commit('GetNewLeadsAdmin', response.data))
                .catch(error => {});
        },
        async NewLeadsKeyAccount({ commit }) {
            let service = new AssignedsService();
            service.keyAccountNewLeads().then(response => commit('GetNewLeadsKeyAccount', response.data))
                .catch(error => {});
        },
        async ActivityKeyAccount({ commit }) {
            let service = new ActivityService();
            service.keyAccountActivity().then(response => commit('GetActivityKeyAccount', response.data))
                .catch(error => {});
        },
        async Activity({ commit }) {
            let service = new ActivityService();
            service.getAll().then(response => commit('GetActivity', response.data))
                .catch(error => {});
        },
        async UserListKeyAccount({ commit }) {
            let service = new UsersService();
            service.keyAccountList().then(response => commit('GetUserListKeyAccount', response.data))
                .catch(error => {});
        },
        async keyAccountLeads({ commit }) {
            let service = new AssignedsService()
            service.keyAccountLeads().then(response => commit('GetkeyAccountLeads', response.data))
                .catch(error => {});
        },
        UserDetails({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                let service = new UsersService();
                service.getUserDetails(idUser).then(response => {
                  commit('DetailUser', response.data);
                  resolve();
                })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        UserActions({ commit }, data) {
            return new Promise((resolve, reject) => {
                let service = new UsersService();
                service.getUserActions(data.id ,data.month ).then(response => {
                    commit('ActionUser', response.data);
                    resolve();
                })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        async UserTagClouds({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                let service = new TagsService();
                service.userTagClouds(idUser).then(response => commit('TagCloudUser', response.data));
            });
        },
        async UserLastActivitys({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                let service = new UsersService();
                service.lastActivity(idUser).then(response => commit('LastActivityUser', response.data))
                .catch(error => reject(error));
            });
        },
        async UserDonuts({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                let service = new UsersService();
                service.getUserDonuts(idUser).then(response => commit('DonutsUser', response.data))
                .catch(error => reject(error));
            });
        },
        async UserActivitys({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                let service = new ActivityService();
                service.getById(idUser).then(response => commit('ActivityUser', response.data));
            });
        },
        async UserRemarks({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                let service = new RemarksService();
                service.getById(idUser).then(response => { commit('RemarksUser', response.data)});
            });
        },
        remarks({ commit }, data) {
            return new Promise((resolve, reject) => {
                // const item = {
                //     comment: data.comment,
                //     id_client: data.id_client
                // };
                let service = new RemarksService();
                service.add(data).then(res => resolve(res.data))
                    .catch(err => {
                        reject(err);
                    });;
            });
        },
        async UserLastExport({ commit }, idUser) {
            let service = new ExportsService();
            service.getById(idUser).then(response => commit('LastExportUser', response.data))
                .catch(error => {});

        },
        UserProfileExport({ commit }, data) {
            return new Promise((resolve, reject) => {
                //     Authorization: 'Bearer ' + localStorage.getItem('token')
                // };
                const item = {
                    id_admin: data.id_admin,
                    id_client: data.id_client
                };
                let service = new ExportsService();
                service.add(item).then(res => resolve(res))
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        async KeyAcoountList({ commit }, idUser) {
            let service = new AssignedsService();
            service.getAll().then(response => commit('ListKeyAcoount', response))
                .catch(error => {});
        },
        AssignKeyAccount({ commit }, data) {
            return new Promise((resolve, reject) => {
                const item = {
                    id_client: data.id_client,
                    id_keyAccount: data.id_keyAccount
                };
                let service = new AssignedsService();
                service.add(item).then(res => resolve(res))
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editKeyAccount({ commit }, data) {
            return new Promise((resolve, reject) => {
                // const headers = {
                //     Authorization: 'Bearer ' + localStorage.getItem('token')
                // };
                const item = {
                    id_client: data.id_client,
                    id_keyAccount: data.id_keyAccount
                };
                // axios({
                //     url: process.env.VUE_APP_API_ENDPOINT + 'editKeyAccount',
                //     headers,
                //     data: item,
                //     method: 'POST'
                // })
                //     .then(resolve)
                //     .catch(err => {
                //         reject(err);
                //     });
                let service = new AssignedsService();
                service.editKeyAccount(item).then(res => resolve(res))
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        async isClient({ commit }, idClient) {
            let service = new ClientsService()
            service.getById(idClient).then(response => commit('isClient', response.data.isClient))
                .catch(error => {});
        },
        SaveAsClient({ commit }, data) {
            return new Promise((resolve, reject) => {
                const item = {
                    id_client: data.id_client
                };
                let service = new ClientsService();
                service.add(item).then(res => resolve(res))
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        changeStatus({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let service = new ClientsService();
                service.changeStatus(payload).then(res => resolve(res))
                    .catch(err => {
                        reject(err);
                    });
            });
        }
    },

    getters: {
        AllNbrUsers: state => state.AllNbrUsers,
        AllCategoryGraphe: state => state.AllCategoryGraphe,
        HotContent: state => state.HotContent,
        AllPersonaGraphe: state => state.AllPersonaGraphe,
        ActionPerM: state => state.ActionPerM,
        listComment: state => state.listComment,
        TagsCloudALL: state => state.TagsCloudALL,
        LAssigned: state => state.LAssigned,
        LAssignedAdmin: state => state.LAssignedAdmin,
        UnassignedLeadsAdm: state => state.UnassignedLeadsAdm,
        UnassignedLeadsKey: state => state.UnassignedLeadsKey,
        NewLeadsAdm: state => state.NewLeadsAdm,
        ActivityKeyAccount: state => state.ActivityKeyAccount,
        UserList: state => state.UserList,
        Activity: state => state.Activity,
        UserDetail: state => state.UserDetail,
        UserAction: state => state.UserAction,
        UserTagCloud: state => state.UserTagCloud,
        UserLastActivity: state => state.UserLastActivity,
        UserDonut: state => state.UserDonut,
        UserActivity: state => state.UserActivity,
        UserRemarks: state => state.UserRemarks,
        UserLastExp: state => state.UserLastExp,
        KeyAccount: state => state.KeyAccount,
        TestClient: state => state.TestClient,
        UserListKeyaccount: state => state.UserListKeyaccount,
        keyAccountLeads: state => state.keyAccountLeads,
        NewLeadsKeyACC: state => state.NewLeadsKeyACC
    }
};
export default back;
