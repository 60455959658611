import { ArticlesService } from '@/services/ApiService'

const postesState = {
  state: {
    loading: true,
    lazyLoading: false,
    params: [],
    displayedArticles: [],
    articlesPage: [],
    busy: false
  },
  mutations: {},
  actions: {
    async getArticlesData ({ state, commit }, filter = false) {
      state.loading = true
      const articleService = new ArticlesService()
      state.params = { ...state.params, page: filter ? 1 : state.params.page }
      await articleService
        .getAll(state.params)
        .then(response => {
          state.lazyLoading = false;
          if (filter) {
            state.displayedArticles = response.data
          } else {
            state.displayedArticles = [
              ...Object.values(state.displayedArticles),
              ...Object.values(response.data)
            ]
          }
          state.articlesPage = response
          state.loading = false
          if (response.last_page === response.current_page) {
            state.busy = true
          } else {
            state.busy = false
          }

        })
    },
    loadMore ({ state, dispatch }) {
      if (!state.busy) {
        state.params = {
          ...state.params,
          page: state.articlesPage.current_page + 1
        }
        dispatch('getArticlesData')
        state.busy = true
      }
    },
    setParams ({ state, dispatch }, params = {}) {
      state.lazyLoading = true ;
      if (params.tag) {
        state.params = {
          ...state.params, filter : ''
        }
        let stateTags = state.params.tags ? state.params.tags.split(',') : [];
        if (stateTags.includes(params.tag.toString())) {
          state.params = {
            ...state.params,
            tags:
            stateTags.length > 1
                  ? state.params.tags.replace(',' + params.tag, '')
                  : ''
          }
        } else {
          state.params = {
            ...state.params,
            tags: state.params.tags
              ? state.params.tags + ',' + params.tag
              : ',' + params.tag
          }
        }
      } else {
        if(params.filter) {
        state.params = params ? { ...state.params, ...params , tags: '' } : [] ;
        }else{
          state.params = params ? { ...state.params, ...params } : []
        }
      }
      dispatch('getArticlesData', true)
    }
  },

  getters: {
    loading: state => state.loading,
    params: state => state.params,
    displayedArticles: state => state.displayedArticles,
    articlesPage: state => state.articlesPage,
    busy: state => state.busy ,
    lazyLoading : state => state.lazyLoading
  }
}
export default postesState
