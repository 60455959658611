import { ConstantsService } from "@/services/ApiService";


const ConstantsState = {
    state: {
        Constants : null
    },
    mutations: {
        SetConstants(state, constants){
            state.Constants = constants ;
        }
    },
    actions: {
        async GetNewConstants({state ,commit} ){
            let constantsService = new ConstantsService();
            await constantsService.getAll().then(res => {
                commit("SetConstants",res)
            })
        }
    },
    getters:{
        Constants : state =>  state.Constants ,
    }
}

export default ConstantsState;
