
import auth from './modules/auth.js'
import front from './modules/front'
import back from './modules/back'
import manage from './modules/manage'
import articlesState from './modules/article'
import constantsState from './modules/constants'
import TagsState from './modules/tags'
import CategoriesState from './modules/categories'
import PersonasState from './modules/personas'
import createPersistedState from "vuex-persistedstate";
import UsersState from './modules/users.js'
import postesState from './modules/postes.js'
import { createStore } from 'vuex'
import ModalState from './modules/modal-state.js'

const store = createStore({

  modules : {
    auth,
    front,
    back,
    manage,
    articlesState,
    constantsState,
    TagsState,
    CategoriesState,
    PersonasState,
    UsersState ,
    postesState ,
    ModalState
  },
  state: {
    link: ''
  },
  mutations: {

  },
  actions: {

  },
  getters: {

  },
  plugins: [createPersistedState()],
})

export default store;
