import $ from 'jquery'

const ModalState = {
  state: {
    openInterssenbloc : false,
    openLoginbloc : false,
    openOrderAdressModal :false ,
  },
  mutations: {
    SetInterssenbloc (state, params) {
      state.openInterssenbloc = params
    },
    SetLoginbloc (state, params) {
      state.openLoginbloc = params
    }
  },
  actions: {
    async updateInterssenbloc ({ commit } , params ) {
      await commit("SetInterssenbloc",params);
    },
    async updateLoginbloc ({ commit } , params ) {
      await commit("SetLoginbloc",params);
    },
    async updateOrderAdressModal ({state , commit } , params ) {
      state.openOrderAdressModal = !state.openOrderAdressModal;
    },
  },

  getters: {
    openInterssenbloc: state => state.openInterssenbloc,
    openLoginbloc: state => state.openLoginbloc,
    openOrderAdressModal: state => state.openOrderAdressModal,
  }
}
export default ModalState;
