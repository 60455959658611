import { ArticlesService } from '@/services/ApiService'

const articlesState = {
  state: {
    filtredArticles: [],
    filterParams: { page: 1, tags: '', categories: '', totalElements: 1, filter: '' },
    mustRefresh: false
  },
  mutations: {
    setFiltredArticles (state, filtredArticles) {
      state.filtredArticles = filtredArticles
    },
    setFilterParams (state, filterParams) {
      state.filterParams = filterParams
    },
    setMustRefresh (state, mustRefresh) {
      state.mustRefresh = mustRefresh
    },
    resetFilterParams (state) {
      state.filterParams = { page: 0, tags: '', categories: '', totalElements: 1, filter: '' }
    }
  },
  actions: {
    async getFiltredArticles ({ state, commit }) {
      state.filterParams.page += 1

      const service = new ArticlesService()
      await service.getAll(state.filterParams).then(response => {
        commit('setFiltredArticles', response.data)
        commit('setFilterParams', { page: response.current_page, tags: state.filterParams.tags, categories: state.filterParams.categories, totalElements: response.total, totalPages: response.last_page, filter: state.filterParams.filter })
      })
    },

    async resetFilterParams ({ commit, dispatch }) {
      commit('resetFilterParams')
      await dispatch('getFiltredArticles')
    },

    async setFilterParams ({ commit, dispatch }, params) {
      commit('setFilterParams', params)
      await dispatch('getFiltredArticles')
    }
  },

  getters: {
    filtredArticles: state => state.filtredArticles,
    filterParams: state => state.filterParams,
    mustRefresh: state => state.mustRefresh
  }
}
export default articlesState
