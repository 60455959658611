import Axios from 'axios'

export default class Crud {
  constructor ($url) {
    this.endPoint = import.meta.env.VITE_API_ENDPOINT + $url
  }

  async getAll (params = {}) {
    return await Axios.get(this.endPoint, { params }).then(response => response.data).catch(error => Promise.reject(error))
  }

  async getById (id, params = {}) {
    return await Axios.get(this.endPoint + '/' + id, { params }).then(response => response).catch(error => Promise.reject(error))
  }

  async add (form, params = {}) {
    return await Axios.post(this.endPoint, form, { params }).then(response => Promise.resolve(response)).catch(error => Promise.reject(error))
  }

  async update (form, id, params = {}) {
    return await Axios.post(this.endPoint + '/' + id, form, {
      params,
      maxContentLength: 100000000,
      maxBodyLength: 1000000000
    }).then(response => response).catch(error => Promise.reject(error))
  }

  async delete (id, params = {}) {
    return await Axios.delete(this.endPoint + '/' + id, { params }).then(response => response).catch(error => error)
  }

  getCustom (extraPath, params = {}) {
    return Axios.get(this.endPoint + '/' + extraPath, { params }).then(response => response).catch(error => error)
  }
}
