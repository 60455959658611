import Axios from "axios"
import { Crud } from "."


export default class UsersService extends Crud {
    constructor(){
        super('new/users')
    }


    async downloadUsers(ids , params = {}){
        return await Axios.get(this.endPoint + '/export/'+ids ,{params : {...params}, responseType: "blob" }).then(response => response).catch(error => Promise.reject(error))
    }

    async changePassword(form, params = {}){
        return await Axios.post(this.endPoint + '/changeUserPassword',form ,{params}).then(response => response).catch(error => error)
    }

    async listTags( params = {}){
        return await Axios.get(this.endPoint + '/tags/all' ,{params}).then(response => response).catch(error => error)
    }

    async actionPerMonth(month , params = {}){
        return await Axios.get(this.endPoint + '/action-per-month/' +month ,{params}).then(response => response).catch(error => error)
    }

    async keyAccountList( params = {}){
        return await Axios.get(this.endPoint + '/key-account/list' ,{params}).then(response => response).catch(error => error)
    }

    async getUserDetails( id , params = {}){
        return await Axios.get(this.endPoint + '/detail/' + id ,{params}).then(response => response).catch(error => Promise.reject(error))
    }

    async getUserActions( id , month , params = {}){
        return await Axios.get(this.endPoint + '/actions/' + id +'/'+month,{params}).then(response => response).catch(error => error)
    }

    async getUserDonuts( id , params = {}){
        return await Axios.get(this.endPoint + '/donuts/' + id ,{params}).then(response => response).catch(error => error)
    }

    async lastActivity( id , params = {}){
        return await Axios.get(this.endPoint + '/last-activity/' + id ,{params}).then(response => response).catch(error => error)
    }

    async deleteUnassignedLeads( id , params = {}){
        return await Axios.get(this.endPoint + '/delete-unassigned-leads/' + id ,{params}).then(response => response).catch(error => Promise.reject(error))
    }

    async userMontion( params = {}){
        return await Axios.get(this.endPoint + '/montion/all',{params}).then(response => response).catch(error => Promise.reject(error))
    }

    async defineQualified(id , params = {}){
        return await Axios.get(this.endPoint + '/define-qualified/' + id , {params}).then(response => response).catch(error => Promise.reject(error))
    }


    async updateUserProfile(form, params = {}){
        return await Axios.post(this.endPoint + '/update/profile',form ,{params}).then(response => response).catch(error => Promise.reject(error))
    }

    async updateUserTrace(form, params = {}){
        return await Axios.post(this.endPoint + '/update/trace',form ,{params}).then(response => response).catch(error => Promise.reject(error))
    }

    async getListFavoriten(params = {}){
        return await Axios.get(this.endPoint + '/list/favoriten' , {params}).then(response => Promise.resolve(response)).catch(error => Promise.reject(error))
    }

}
