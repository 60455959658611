import Axios from "axios"
import { Crud } from '.'

export default class TagsService extends Crud {
  constructor () {
    super('new/tags')
  }

  // getAll =>    params = { per_page , filter , page }               || => return list article with pagination

  // getById =>   return tag                                          ||

  // add =>        params = { per_page , filter , page  }              ||
  //              data => {name : ''}                                 ||

  // update =>     params = { per_page , filter , page }               ||
  //              data => {name : ''}                                 ||
    
  // delete =>     params = { per_page , filter , page }               ||


    async getAllAsArray(params = {}){
      return await Axios.get(this.endPoint + '/array/all' ,{params}).then(response => response).catch(error => error)
  }

  async getDisplayTags(params = {}){
    return await Axios.get(this.endPoint + '/display/all' ,{params}).then(response => response).catch(error => error)
  }

  async getAllPublic(params = {}){
    return await Axios.get(this.endPoint + '/public/all' ,{params}).then(response => response).catch(error => error)
  }

  async tagClouds(type ,params = {}){
    return await Axios.get(this.endPoint + '/clouds/' + type ,{params}).then(response => response).catch(error => error)
  }

  async userTagClouds(id ,params = {}){
    return await Axios.get(this.endPoint + '/user-clouds/'+id ,{params}).then(response => response).catch(error => error)
  }

  async userTagInteressen(data ,params = {}){
    return await Axios.post(this.endPoint + '/tags-update/user',data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error))
  }
}
