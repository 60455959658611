import Axios from 'axios';
import { Crud } from '.';

export default class InscriptionsService extends Crud {
    constructor() {
        super('new/inscriptions');
    }

    async getListAnnonce(params){
        return await Axios.get(this.endPoint + '/annonce/list' ,{params}).then(response => response).catch(error => error)
    }

    async getUserInterests(params){
        return await Axios.get(this.endPoint + '/user/interest' ,{params}).then(response => response).catch(error => error)
    }
}