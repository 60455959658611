// import pages

// import ArticleList from './src/pages/management/ArticleList.vue'
// import CategoriesList from './src/pages/management/ListCategories.vue'
// import TagsList from './src/pages/management/ListTags.vue'
// import PersonasList from './src/pages/management/ListPersons.vue'
// import AccountsList from './src/pages/management/ListAccounts.vue'
// import BackendDashboard from './src/pages/backend/Dashboard.vue'
// import backendNews from './src/pages/backend/New.vue'
// import backendUserList from './src/pages/backend/UserList.vue'
// import HeaderComponent from './src/components/HeaderComponent.vue'
// import UserEdit from './src/pages/backend/UserEdit.vue'
// import EditProfile from './src/pages/EditProfile.vue'
// import RenderSchemaOrg from './src/components/client/RenderSchemaOrg.vue'
// import ReminderInteressenModalComponent from './src/components/client/reminderInteressenModalComponent.vue'
// vue packages
import "@protonemedia/laravel-splade/dist/style.css";

import { createApp , defineAsyncComponent} from "vue/dist/vue.esm-bundler.js";
import { renderSpladeApp, SpladePlugin } from '@protonemedia/laravel-splade'

import store from './src/store';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueTour from 'vue3-tour';
// import VueScrollTo from 'vue-scrollto';
// import Vue3Progress from 'vue3-progress/';
// import 'vue-assets/assets/css/primeflex/primeflex.min.css';
import 'vue3-tour/dist/vue3-tour.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialSharing from 'vue-social-sharing';
import PrimeVue from 'primevue/config';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// import 'boxicons';
import 'primevue/resources/themes/saga-blue/theme.css'; // theme
import 'primevue/resources/primevue.min.css'; // core css
import 'primeicons/primeicons.css';
// import './scss/style.scss';
import 'boxicons/css/boxicons.min.css';
// import MultiSelect from 'primevue/multiselect';
// import Carousel from 'primevue/carousel';
// import Dialog from "primevue/dialog";
// import LikeButtonComponent from './src/components/client/LikeButtonComponent.vue'
// import ScrollTop from "primevue/scrolltop";
// import ListFavoritenComponent from './src/components/client/ListFavoritenComponent.vue' ;
// import RenderDiscovryTour from './src/components/client/RenderDiscovryTour.vue' ;
// import Password from 'primevue/password';

const el = document.getElementById('app')

const app = createApp({
    render: renderSpladeApp({ el })
});
    app.use(SpladePlugin , {
        "components" : {
            ArticleList  : defineAsyncComponent(() => import('./src/pages/management/ArticleList.vue')),
            CategoriesList : defineAsyncComponent(() => import('./src/pages/management/ListCategories.vue')),
            TagsList : defineAsyncComponent(() => import('./src/pages/management/ListTags.vue')),
            PersonasList : defineAsyncComponent(() => import('./src/pages/management/ListPersons.vue')),
            AccountsList : defineAsyncComponent(() => import('./src/pages/management/ListAccounts.vue')),
            BackendDashboard  : defineAsyncComponent(() => import('./src/pages/backend/Dashboard.vue')),
            backendNews : defineAsyncComponent(() => import('./src/pages/backend/New.vue')),
            backendUserList : defineAsyncComponent(() => import('./src/pages/backend/UserList.vue')),
            HeaderComponent : defineAsyncComponent(() => import('./src/components/HeaderComponent.vue')),
            MultiSelect : defineAsyncComponent(() => import('primevue/multiselect')),
            UserEdit : defineAsyncComponent(() => import('./src/pages/backend/UserEdit.vue')),
            Carousel : defineAsyncComponent(() => import('primevue/carousel')),
            Dialog : defineAsyncComponent(() => import("primevue/dialog")),
            LikeButtonComponent : defineAsyncComponent(() => import('./src/components/client/LikeButtonComponent.vue')),
            EditProfile  : defineAsyncComponent(() => import('./src/pages/EditProfile.vue')),
            ScrollTop : defineAsyncComponent(() => import("primevue/scrolltop")),
            ListFavoritenComponent  : defineAsyncComponent(() => import('./src/components/client/ListFavoritenComponent.vue')),
            Password : defineAsyncComponent(() => import("primevue/password")),
            RenderDiscovryTour : defineAsyncComponent(() => import('./src/components/client/RenderDiscovryTour.vue')),
            RenderSchemaOrg : defineAsyncComponent(() => import('./src/components/client/RenderSchemaOrg.vue')),
            ReminderInteressenModalComponent : defineAsyncComponent(() => import('./src/components/client/reminderInteressenModalComponent.vue')),
            ListInterssenComponent : defineAsyncComponent(() => import('./src/components/client/ListInterssenComponent.vue')),
            ProfileStateComponent : defineAsyncComponent(() => import('./src/components/client/ProfileStateComponent.vue')),
            SharePost : defineAsyncComponent(() => import('./src/components/client/SharePost.vue')),
            ScrollToId : defineAsyncComponent(() => import('./src/components/client/ScrollToId.vue')) ,

        }
    });

    app.use(CKEditor);
    app.use(VueSocialSharing)
    app.use(Donut)
    app.use(VueFusionCharts, FusionCharts, Column2D, FusionTheme)
    app.use(ElementPlus)
    app.use(VueAxios, axios)
    app.use(PrimeVue);
    app.use(VueTour)
    app.use(store);
    app.mount(el);
