import { UsersService } from '@/services/ApiService'

const UsersState = {
  state: {
    Users: null,
    listFavoriten :null ,
  },
  mutations: {
    SetUsers (state, users) {
      state.Users = users
    },
    SetListFavoriten (state, listFavoriten) {
        state.listFavoriten = listFavoriten
    }
  },
  actions: {
    async GetUsersListe ({ commit } , params = {}) {
      const service = new UsersService()
      await service.getAll(params).then(response => {
        commit('SetUsers',response)
      })
    },
    async GetListFavoriten ({ commit } , params = {}) {
      const service = new UsersService()
      await service.getListFavoriten(params).then(response => {
        commit('SetListFavoriten',response.data)
      })
    },

  },

  getters: {
    newUsers: state => state.Users,
    newListFavoriten: state => state.listFavoriten,
  }
}
export default UsersState
