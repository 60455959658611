import Axios from 'axios'
import { Crud } from '.'

export default class CategoryService extends Crud {
  constructor () {
    super('new/categories')
  }

  async postChart( params = {}){
    return await Axios.get(this.endPoint + '/post/chart',{params}).then(response => response).catch(error => error)
  }
}
