import Axios from "axios"
import { Crud } from "."


export default class PersonaService extends Crud {
    constructor(){
        super('new/personas')
    }


    
    // getAll =>    params = { per_page , filter , page }               || => return list article with pagination 

    // getById =>   return persona                                      ||

    //add =>        params = { per_page , filter , page  }              || 
    //              data => {name : ''}                                 ||

    //update =>     params = { per_page , filter , page }               || 
    //              data => {name : ''}                                 ||
    
    //delete =>     params = { per_page , filter , page }               ||

    async hotContent(month , params = {}){
        return await Axios.get(this.endPoint + '/hot-content/' + month ,{params}).then(response => response).catch(error => error)
    }

    async postChart( params = {}){
        return await Axios.get(this.endPoint + '/post/chart',{params}).then(response => response).catch(error => error)
    }
}