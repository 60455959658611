import Axios from "axios"
import { Crud } from "."


export default class AssignedsService extends Crud {
    constructor(){
        super('new/assigneds')
    }

    async keyAccountList( params = {}){
        return await Axios.get(this.endPoint + '/key-account/list',{params}).then(response => response).catch(error => error)
    }

    async adminList( params = {}){
        return await Axios.get(this.endPoint + '/admin/list',{params}).then(response => response).catch(error => error)
    }

    async adminUnassignedLeads( params = {}){
        return await Axios.get(this.endPoint + '/admin/unassigned-leads',{params}).then(response => response).catch(error => error)
    }

    async keyAccountLeads( params = {}){
        return await Axios.get(this.endPoint + '/key-account/leads',{params}).then(response => response).catch(error => error)
    }

    async adminLeads( params = {}){
        return await Axios.get(this.endPoint + '/admin/leads',{params}).then(response => response).catch(error => error)
    }

    async keyAccountNewLeads( params = {}){
        return await Axios.get(this.endPoint + '/key-account/new-leads',{params}).then(response => response).catch(error => error)
    }

    async editKeyAccount(form , params = {}){
        return await Axios.post(this.endPoint + '/key-account/edit',form,{params}).then(response => response).catch(error => error)
    }

    async AssignedCount( params = {}){
        return await Axios.get(this.endPoint + '/admin/assigned-count',{params}).then(response => response).catch(error => Promise.reject(error))
    }
}