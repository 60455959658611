import { PersonaService } from "@/services/ApiService"


const PersonasState = {
    state: {
        Personas: null,
    },
    mutations:{
        setPersonas(state , personas) {
            state.Personas = personas
        }
    },
    actions: {
        GetNewPersonas({ commit},params){
            let personasService = new PersonaService();
            personasService.getAll(params).then(res => {
                commit("setPersonas",res);
            })
        }
    },
    getters: {
        newPersonas : state => state.Personas
    }
}

export default PersonasState;
