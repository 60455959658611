import axios from 'axios';
import {
    ArticlesService,
    CategoryService,
    ConstantsService,
    FavoritenService,
    OrderService,
    ShareService,
    TagsService,
    UsersService,
} from '@/services/ApiService';
import InscriptionsService from '@/services/ApiService/inscriptions.services';

const front = {
    state: {
        status: 'connected',
        token: localStorage.getItem('token') || '',
        allCategory: '',
        AllTags: '',
        ArtFour: '',
        TagsListALL: '',
        ArticleDetail: '',
        RelatedTags: '',
        Article: '',
        allConstants: '',
        ArticleByCat: '',
        FavPost: '',
        ListFav: '',
        TagsPublic: '',
        publicArticle: '',
    },
    mutations: {
        GetTagsPublic(state, TagsPublic) {
            state.TagsPublic = TagsPublic;
            state.status = 'Get TagsPublic Sucess';
        },
        GetCategory(state, allCategory) {
            state.allCategory = allCategory;
            state.status = 'Get category Sucess';
        },
        GetTags(state, AllTags) {
            state.AllTags = AllTags;
            state.status = 'Get Tags Sucess';
        },
        GetArticleFour(state, ArtFour) {
            state.ArtFour = ArtFour;
            state.status = 'Get ArticleFour Sucess';
        },
        GetAllTags(state, TagsListALL) {
            state.TagsListALL = TagsListALL;
            state.status = 'Get Tags List ALL Sucess';
        },
        GetArticleDetail(state, ArticleDetail) {
            state.ArticleDetail = ArticleDetail;
            state.status = 'Get article Sucess';
        },
        GetRelatedTags(state, RelatedTags) {
            state.RelatedTags = RelatedTags;
            state.status = 'Get Related Tags Sucess';
        },
        GetArticles(state, Article) {
            state.Article = Article;
            state.status = 'Get Article Sucess';
        },

        GetConstants(state, allConstants) {
            state.allConstants = allConstants;
            state.status = 'Get allConstants Sucess';
        },
        GetArticleByCat(state, ArticleByCat) {
            state.ArticleByCat = ArticleByCat;
            state.status = 'Get ArticleByCat Sucess';
        },
        GetFavPost(state, FavPost) {
            state.FavPost = FavPost;
            state.status = 'Get FavPost Sucess';
        },
        GetListFav(state, ListFav) {
            state.ListFav = ListFav;
            state.status = 'Get ListFav Sucess';
        },
        GetPublicArticle(state, publicArticle) {
            state.publicArticle = publicArticle;
            state.status = 'Get publicArticle Sucess';
        },
    },
    actions: {
        async category({ commit }) {
            let service = new CategoryService();
            service
                .getAll()
                .then((response) => commit('GetCategory', response.data))
                .catch((error) => {});
        },

        async constants({ commit }) {
            let service = new ConstantsService();
            service
                .getAll()
                .then((response) => commit('GetConstants', response))
                .catch((error) => {});
        },

        share({ commit }, { idArticle, network }) {
            return new Promise((resolve, reject) => {
                const item = {
                    idarticle: idArticle,
                    network: network,
                };
                let service = new ShareService();
                service
                    .add(item)
                    .then(resolve)
                    .catch((err) => {
                        commit('post_error');
                        reject(err);
                    });
            });
        },
        async tagsList({ commit }) {
            let service = new UsersService();
            service
                .listTags()
                .then((response) => commit('GetTags', response.data))
                .catch((error) => {});
        },
        ArticleListFour({ commit }, IdArticle) {
            return new Promise((resolve, reject) => {
                let service = new ArticlesService();
                service
                    .listFourArt(IdArticle)
                    .then((response) => commit('GetArticleFour', response.data))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async allTags({ commit }) {
            let service = new TagsService();
            await service
                .getDisplayTags()
                .then((response) => commit('GetAllTags', response.data))
                .catch((error) => {});
        },
        async TagsPublic({ commit }) {
            let service = new TagsService();
            service
                .getAllPublic()
                .then((response) => commit('GetTagsPublic', response.data))
                .catch((error) => {});
        },
        AddViewNbr({ state, commit }, IdArticle) {
            return new Promise((resolve, reject) => {
                // if(state.isLoggedIn){
                let service = new ArticlesService();
                service
                    .addViewNbr(IdArticle)
                    .then((res) => resolve(res))
                    .catch((err) => {
                        reject(err);
                    });
                // }
            });
        },
        ArticleRelatedTags({ commit }, IdArticle) {
            return new Promise((resolve, reject) => {
                let service = new TagsService();
                service
                    .getById(IdArticle)
                    .then((response) => commit('GetRelatedTags', response.data))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        UpdateUserTag({ commit }, IdArticle, filter = false) {
            return new Promise((resolve, reject) => {
                const headers = {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                };
                axios({
                    url: import.meta.env.VITE_API_ENDPOINT + 'new/tags/user-update/' + IdArticle,
                    headers,
                    method: 'post',
                    params: { filter },
                })
                    .then((res) => resolve(res))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async FavPosts({ commit }) {
            let service = new InscriptionsService();
            service
                .getListAnnonce()
                .then((response) => commit('GetFavPost', response.data))
                .catch((error) => {});
        },
        async listFavoriten({ commit }) {
            let service = new FavoritenService();

            await service
                .getAll()
                .then((response) => {
                    commit('GetListFav', response);
                })
                .catch((error) => {});
        },
        async UpdateUserPostFavoriten({ commit }, idarticle) {
            return await new Promise(async (resolve, reject) => {
                let service = new FavoritenService();
                await service
                    .add({ idarticle })
                    .then((result) => resolve(result))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        inscription({ commit }, IDARTICLE) {
            return new Promise((resolve, reject) => {
                const item = {
                    idarticle: IDARTICLE,
                };
                let service = new InscriptionsService();
                service
                    .add(item)
                    .then((res) => resolve(res))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        order({ commit }, res) {
            return new Promise((resolve, reject) => {
                const item = {
                    idarticle: res.idarticle,
                    address_order: res.address_order,
                };
                let service = new OrderService();
                service
                    .add(item)
                    .then()
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async publicArticle({ commit }, idarticle) {
            const headers = {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            };
            await axios
                .get(import.meta.env.VITE_API_ENDPOINT + 'publicArticle/' + idarticle, { headers })
                .then((response) => commit('GetPublicArticle', response.data))
                .catch((error) => {});
        },
    },

    getters: {
        allCategory: (state) => state.allCategory,
        AllTags: (state) => state.AllTags,
        ArtFour: (state) => state.ArtFour,
        TagsListALL: (state) => state.TagsListALL,
        ArticleDetail: (state) => state.ArticleDetail,
        RelatedTags: (state) => state.RelatedTags,
        Article: (state) => state.Article,
        allConstants: (state) => state.allConstants,
        ArticleByCat: (state) => state.ArticleByCat,
        FavPost: (state) => state.FavPost,
        ListFav: (state) => state.ListFav,
        TagsPublic: (state) => state.TagsPublic,
        publicArticle: (state) => state.publicArticle,
    },
};
export default front;
