import Axios from "axios"
import { Crud } from "."


export default class ActivityService extends Crud {
    constructor(){
        super("new/activity-log")
    }

    async keyAccountActivity( params = {}){
        return await Axios.get(this.endPoint + '/key-account/get',{params}).then(response => response).catch(error => error)
    }
}