import axios from 'axios'
import { ArticlesService, CategoryService, PersonaService, TagsService, UsersService } from '@/services/ApiService'

const manage = {
  state: {
    allArticles: '',
    allCategories: '',
    allManageTags: '',
    allPersona: '',
    ManageUser: '',
    TagsArray: '',
    AssignedList: ''
  },
  mutations: {
    GetArticles (state, allArticles) {
      state.allArticles = allArticles
      state.status = 'Get articles Success'
    },
    GetCategories (state, allCategories) {
      state.allCategories = allCategories
      state.status = 'Get categories Success'
    },
    GetTags (state, allManageTags) {
      state.allManageTags = allManageTags
      state.status = 'Get tags Success'
    },
    GetPersonas (state, allPersona) {
      state.allPersona = allPersona
      state.status = 'Get personas Success'
    },
    GetManageUserList (state, ManageUser) {
      state.ManageUser = ManageUser
      state.status = 'Get Key ManageUser Success'
    },
    GetTagsArray (state, TagsArray) {
      state.TagsArray = TagsArray
      state.status = 'Get TagsArray Success'
    },
    GetAssignedList (state, AssignedList) {
      state.AssignedList = AssignedList
      state.status = 'Get Assigned List Success'
    }
  },
  actions: {
    // Article APIs
    async articles ({ commit },{ per_page , page , filter }) {
      const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }

      let service = new ArticlesService();
      await service.getMangementArticle({per_page , page , filter}).then(response => {
            commit('GetArticles', response.data)
          })
          .catch(error => {})
    },
    async deleteArticle ({ state , commit }, res) {
    //   return await new Promise((resolve, reject) => {
        const headers = {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }

        let service = new ArticlesService();
        await service.delete(res).then(response => {
              commit('GetArticles', response.data)
              return Promise.resolve();
            })
            .catch(error => Promise.reject())
        //   }).catch(err => {
        //       reject(err)
        //     })
    },

    // End articles APIs

    // Category APIs
    async categories ({ commit }) {
      const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      let service = new CategoryService();
      await service.getAll().then(response => commit('GetCategories', response.data))
        .catch(error => {})
    },
    deleteCategory ({ commit }, res) {
      return new Promise((resolve, reject) => {

        let service = new CategoryService();
        service.delete(res).then(response => commit('GetCategories', response.data.data))
          .catch(err => {
            reject(err)
          })
      })
    },
    // End Category APIs

    // Tags APis
    async tags ({ commit },{page ,per_page}) {
      let service = new TagsService();
      service.getAll({page , per_page}).then(response => commit('GetTags', response))
        .catch(error => {})
    },
    async TagsArray ({ commit }) {
      let service = new TagsService();
      service.getAllAsArray().then(response => commit('GetTagsArray', response.data))
        .catch(error => {})
    },
    saveTag ({ state }, res) {
      return new Promise((resolve, reject) => {

        const tag = {
          name: res.name
        }

        let service = new TagsService();
        service.add(tag).then(response => {
          this.dispatch('tags',{page : state.allManageTags.current_page , par_page : state.allManageTags.per_page}) ;
          return resolve(response)
        } )
          .catch(err => {
            return reject(err)
          })
      })
    },
    deleteTag ({ state }, res) {
      return new Promise((resolve, reject) => {
        let service = new TagsService();
        service.delete(res).then(response => this.dispatch('tags',{page : state.allManageTags.current_page , par_page : state.allManageTags.per_page}) )
          .catch(err => {
            reject(err)
          })
      })
    },
    editTag ({ state }, res) {
      return new Promise((resolve, reject) => {


        let tags = new FormData();
        tags.append("name", res.name);
        tags.append("_method", "PATCH");

        let service = new TagsService();
        service.update(tags, res.id).then(response => this.dispatch('tags',{page : state.allManageTags.current_page , par_page : state.allManageTags.per_page}) )
          .catch(err => {
            reject(err)
          })
      })
    },
    // End Tags APIs

    // Personas APIs
    async personas ({ commit }) {
      let service = new PersonaService();
      service.getAll().then(response => commit('GetPersonas', response.data))
        .catch(error => {})
    },
    savePersona ({ commit }, res) {
      return new Promise((resolve, reject) => {
        // const headers = {
        //   Authorization: 'Bearer ' + localStorage.getItem('token')
        // }

        const persona = {
          name: res.name
        }

        // axios({
        //   url: process.env.VUE_APP_API_ENDPOINT + 'manage/persona',
        //   headers,
        //   data: persona,
        //   method: 'POST'
        // })
        //   .then(response => commit('GetPersonas', response.data))
        //   .catch(err => {
        //     reject(err)
        //   })
        let service = new PersonaService();
        service.add(persona).then(response => {
          // if(response.data)
          // commit('GetPersonas', response.data)
          return resolve(response)
        })
          .catch(err => {
            return reject(err)
          })
        })
    },

    editPersona ({ commit }, res) {
      return new Promise((resolve, reject) => {

        let persona = new FormData();
        persona.append('name',res.name);
        persona.append('_method',"PATCH");

        let service = new PersonaService();
        service.update(persona , res.id).then(response => {
          commit('GetPersonas', response.data);
          resolve()
        })
          .catch(err => {
            reject(err)
          })

      })
    },

    async deletePersona ({ commit }, res) {
        let service = new PersonaService();
        await service.delete(res).then(response => {commit('GetPersonas', response.data);
            Promise.resolve()})
          .catch(err => {
            Promise.reject(err)
          })
    },


    AddKeyAcoount ({ commit }, res) {
      return new Promise((resolve, reject) => {
        const headers = {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }

        const keyAcccount = {
          name: res.name,
          email: res.email,
          type: res.type
        }

        let service = new UsersService();
        service.add({form : keyAcccount}).then(response => resolve(response))
          .catch(err => reject(err))
      })
    },

    deleteUser ({ commit }, res) {
      return new Promise((resolve, reject) => {
        let service = new UsersService();
        service.delete(res , {type: '0'}).then(response => commit('GetManageUserList', response.data))
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  getters: {
    allCategories: state => state.allCategories,
    allManageTags: state => state.allManageTags,
    allPersona: state => state.allPersona,
    allArticles: state => state.allArticles,
    ManageUser: state => state.ManageUser,
    TagsArray: state => state.TagsArray,
    AssignedList: state => state.AssignedList
  }
}
export default manage
