import Axios from "axios"
import { Crud } from "."


export default class CategoryService extends Crud {
    constructor(){
        super("new/clients")
    }

    async changeStatus( params = {}){
        return await Axios.get(this.endPoint + '/change/status',{params}).then(response => response).catch(error => error)
    }
}