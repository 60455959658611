import axios from 'axios'
import { Crud, UsersService } from '@/services/ApiService'

const auth = {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    userProfile: '',
    isLoggedIn: '',
    authStatus: '',
    isAdmin: '',
    UserName: ''
    // matomoID: null,
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token) {
      state.status = 'success'
      state.token = token
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
    },
    setUser (state, userProfile) {
      state.userProfile = userProfile
      state.status = 'Sucess'
    }
    // matomoID (id){
    //   state.matomoID = id
    // }
  },
  actions: {
    // matomoID({ commit }, id){
    //   axios({
    //     url: process.env.VUE_APP_API_ENDPOINT+'users/matomo/update',
    //     data: id,
    //     method: 'POST'
    //   }).then(res => {
    //     return new Promise((resolve, reject) => {
    //       commit('matomoID')
    //       resolve()
    //     })
    //   })
    // },
    async login ({ commit , dispatch }, user) {
      return await new Promise((resolve, reject) => {
        commit('auth_request')
        axios({
          url: import.meta.env.VITE_API_ENDPOINT + 'login',
          data: user,
          method: 'POST'
        })
          .then(resp => {
            const token = resp.data.token
            localStorage.setItem('token', token)
            localStorage.setItem('firstLogin', resp.data.firstLogin)
            axios.defaults.headers.common.Authorization = token
            commit('auth_success', token)
            dispatch('profile').then(() => resolve(resp))
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    async logout ({ commit }) {
      await axios.post(import.meta.env.VITE_API_ENDPOINT + 'logout').then(res => {
        return new Promise((resolve, reject) => {
          localStorage.removeItem('firstLogin')
          localStorage.removeItem('token')
          commit('logout')
          resolve()
        })
      })
    },
    async profile ({ state, commit }) {
      const service = new Crud('user')
        await service.getAll().then(response => commit('setUser', response))
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userProfile: state => state.userProfile,
    isAdmin: state => state.userProfile.type,
    UserName: state => state.userProfile.last_name ? state.userProfile.last_name + ' ' + state.userProfile.name : state.userProfile.name,
    UserID: state => state.userProfile.id
  }
}
export default auth
