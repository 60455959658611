import { CategoryService } from "@/services/ApiService"


const CategoriesState = {
    state: {
        Categories : null
    },
    mutations: {
        setCategories (state, categories) {
            state.Categories = categories
        }
    },
    actions: {
        GetNewCategories({ commit},params){
            let categoriesService = new CategoryService()
            categoriesService.getAll(params).then(res => {
                commit("setCategories", res)
            });
        }
    },
    getters : {
        newCategories : state => state.Categories
    }
}

export default CategoriesState
