import { TagsService } from '@/services/ApiService'

const TagsState = {
  state: {
    allTags: null,
    activeTags: []
  },
  mutations: {
    setTags (state, allTags) {
      state.allTags = allTags
    },
    setActiveTags (state, activeTags) {
      state.activeTags = activeTags
      state.status = 'Get Active Tags Sucess'
    }
  },
  actions: {
    GetNewTags ({ commit }, params) {
      const tagsService = new TagsService()
      tagsService.getAll(params).then(response => {
        commit('setTags', response.data)
      })
    },
    GetActiveTags ({ commit }) {
      const tagsService = new TagsService()
      tagsService.getCustom('active/all').then(response => {
        commit('setActiveTags', response.data)
      })
    }
  },
  getters: {
    newTags: state =>  state.allTags,
    activeTags: state => state.activeTags
  }
}

export default TagsState
